// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accident-management-about-js": () => import("./../../../src/pages/accident-management/about.js" /* webpackChunkName: "component---src-pages-accident-management-about-js" */),
  "component---src-pages-accident-management-corporate-government-fleets-js": () => import("./../../../src/pages/accident-management/corporate-government-fleets.js" /* webpackChunkName: "component---src-pages-accident-management-corporate-government-fleets-js" */),
  "component---src-pages-accident-management-faqs-js": () => import("./../../../src/pages/accident-management/faqs.js" /* webpackChunkName: "component---src-pages-accident-management-faqs-js" */),
  "component---src-pages-accident-management-js": () => import("./../../../src/pages/accident-management.js" /* webpackChunkName: "component---src-pages-accident-management-js" */),
  "component---src-pages-accident-management-services-js": () => import("./../../../src/pages/accident-management/services.js" /* webpackChunkName: "component---src-pages-accident-management-services-js" */),
  "component---src-pages-accident-management-sme-fleets-js": () => import("./../../../src/pages/accident-management/sme-fleets.js" /* webpackChunkName: "component---src-pages-accident-management-sme-fleets-js" */),
  "component---src-pages-accident-management-testimonials-js": () => import("./../../../src/pages/accident-management/testimonials.js" /* webpackChunkName: "component---src-pages-accident-management-testimonials-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-booking-confirmed-js": () => import("./../../../src/pages/booking-confirmed.js" /* webpackChunkName: "component---src-pages-booking-confirmed-js" */),
  "component---src-pages-contactless-js": () => import("./../../../src/pages/contactless.js" /* webpackChunkName: "component---src-pages-contactless-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-quote-confirmation-js": () => import("./../../../src/pages/quote/confirmation.js" /* webpackChunkName: "component---src-pages-quote-confirmation-js" */),
  "component---src-pages-quote-detail-js": () => import("./../../../src/pages/quote-detail.js" /* webpackChunkName: "component---src-pages-quote-detail-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-quote-step-five-js": () => import("./../../../src/pages/quote/step-five.js" /* webpackChunkName: "component---src-pages-quote-step-five-js" */),
  "component---src-pages-quote-step-four-js": () => import("./../../../src/pages/quote/step-four.js" /* webpackChunkName: "component---src-pages-quote-step-four-js" */),
  "component---src-pages-quote-step-one-js": () => import("./../../../src/pages/quote/step-one.js" /* webpackChunkName: "component---src-pages-quote-step-one-js" */),
  "component---src-pages-quote-step-six-js": () => import("./../../../src/pages/quote/step-six.js" /* webpackChunkName: "component---src-pages-quote-step-six-js" */),
  "component---src-pages-quote-step-three-js": () => import("./../../../src/pages/quote/step-three.js" /* webpackChunkName: "component---src-pages-quote-step-three-js" */),
  "component---src-pages-quote-step-two-js": () => import("./../../../src/pages/quote/step-two.js" /* webpackChunkName: "component---src-pages-quote-step-two-js" */),
  "component---src-pages-quotes-selection-js": () => import("./../../../src/pages/quotes-selection.js" /* webpackChunkName: "component---src-pages-quotes-selection-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-campaign-js": () => import("./../../../src/templates/campaign.js" /* webpackChunkName: "component---src-templates-campaign-js" */),
  "component---src-templates-panel-beater-profile-js": () => import("./../../../src/templates/panel-beater-profile.js" /* webpackChunkName: "component---src-templates-panel-beater-profile-js" */),
  "component---src-templates-services-list-js": () => import("./../../../src/templates/services-list.js" /* webpackChunkName: "component---src-templates-services-list-js" */),
  "component---src-templates-states-list-js": () => import("./../../../src/templates/states-list.js" /* webpackChunkName: "component---src-templates-states-list-js" */),
  "component---src-templates-suburbs-list-js": () => import("./../../../src/templates/suburbs-list.js" /* webpackChunkName: "component---src-templates-suburbs-list-js" */),
  "component---src-templates-zip-js": () => import("./../../../src/templates/zip.js" /* webpackChunkName: "component---src-templates-zip-js" */)
}

